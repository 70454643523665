import { useEffect, useState } from 'react';

/**
 *  A hook to detect if the user has reached the top of the page, within a given threshold.
 *
 * @param heightThreshold The height threshold in pixels from the top of the
 *  page that will make the hook return true
 * @returns boolean
 */
export default function usePageTop(heightThreshold = 0) {
  const [hasReachedTop, setHasReachedTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > heightThreshold) {
        setHasReachedTop(true);
      } else {
        setHasReachedTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [heightThreshold]);

  return hasReachedTop;
}
