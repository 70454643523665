import DrDivider from '@components/DrDivider';
import PrimaryButton from '@components/buttons/primary/PrimaryButton';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import usePageTop from '@src/hooks/usePageTop';
import { devsing_category_id_icon_map, prodsing_category_id_icon_map } from '@src/utils/constants';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';
import Image from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CategoryItem, OptionButton } from './HomePageFilterBarComponents';
import IconChevronLeftSvg from '/public/ic-chevron-left.svg';
import IconChevronRightSvg from '/public/ic-chevron-right.svg';

const environment = process.env.NEXT_PUBLIC_ENV_NAME || 'local';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomePageFilterBar({
  selectedCategoryId,
  setSelectedCategoryId,
  selectedIsSelling,
  setSelectedIsSelling,
  selectedMinPrice,
  setSelectedMinPrice,
  selectedMaxPrice,
  setSelectedMaxPrice,
  selectedRating,
  setSelectedRating,
  activeFilterCounts,
  handleScrollToTop,
  isMobile,
  applyFilter,
}: {
  selectedCategoryId: string;
  setSelectedCategoryId: (categoryId: string) => void;
  selectedIsSelling: string;
  setSelectedIsSelling: (isSelling: string) => void;
  selectedMinPrice: number;
  setSelectedMinPrice: (minPrice: number) => void;
  selectedMaxPrice: number;
  setSelectedMaxPrice: (maxPrice: number) => void;
  selectedRating: number;
  setSelectedRating: (rating: number) => void;
  activeFilterCounts: number;
  handleScrollToTop: () => void;
  isMobile: boolean;
  applyFilter: ({
    filterMinPrice,
    filterMaxPrice,
    filterIsSelling,
    filterRating,
    filterCategoryId,
  }: {
    filterMinPrice: number;
    filterMaxPrice: number;
    filterIsSelling: string;
    filterRating: number;
    filterCategoryId: string;
  }) => void;
}) {
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [selectedCommissionStatus, setSelectedCommissionStatus] = useState('all');
  const [selectedStarRating, setSelectedStarRating] = useState('0');
  // Value is 'all', 'under_500k', '500k_to_1m', '1m_to_2m', 'over_2m'
  const [selectedPriceRange, setSelectedPriceRange] = useState('all');

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  let categoryIdIconMap;
  if (environment === 'production') {
    // TODO : dreambox - enable in the future
    categoryIdIconMap = prodsing_category_id_icon_map;
  } else {
    categoryIdIconMap = devsing_category_id_icon_map;
  }

  const ref = useRef<HTMLDivElement>(null);

  // Enable click to scroll mechanism.
  // Ref: https://www.embla-carousel.com/guides/previous-and-next-buttons/
  const [emblaRef, emblaApi] = useEmblaCarousel({});

  /**
   * Scroll next page: Scroll to the last index of slidesInView.
   */
  const scrollNextPageEmbla = useCallback(() => {
    if (!emblaApi) return;
    console.log('thanh emblaApi scroll next', emblaApi?.slidesInView());
    emblaApi.scrollTo(emblaApi.slidesInView().slice(-1)[0]);
    // emblaApi.scrollNext();
  }, [emblaApi]);

  /**
   * Scroll previous page: Scroll to index 0 of slidesInView.
   */
  const scrollPrevPageEmbla = useCallback(() => {
    if (!emblaApi) return;
    console.log('thanh emblaApi scroll next', emblaApi?.slidesInView());
    emblaApi.scrollTo(0);
    // emblaApi.scrollPrev();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    console.log('thanh emblaApi', emblaApi.canScrollPrev());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);

    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    setSelectedCommissionStatus(selectedIsSelling);
    setSelectedStarRating(selectedRating.toString());
    if (selectedMinPrice === 0 && selectedMaxPrice === 100000000) {
      setSelectedPriceRange('all');
    } else if (selectedMinPrice === 0 && selectedMaxPrice === 499000) {
      setSelectedPriceRange('under_500k');
    } else if (selectedMinPrice === 500000 && selectedMaxPrice === 1000000) {
      setSelectedPriceRange('500k_to_1m');
    } else if (selectedMinPrice === 1000000 && selectedMaxPrice === 2000000) {
      setSelectedPriceRange('1m_to_2m');
    } else if (selectedMinPrice === 2001000 && selectedMaxPrice === 100000000) {
      setSelectedPriceRange('over_2m');
    }
  }, [selectedIsSelling, selectedMinPrice, selectedMaxPrice, selectedRating]);

  // functions
  const handleClickOpen = () => {
    setIsFilterPopupOpen(true);
  };

  const handleClose = () => {
    setIsFilterPopupOpen(false);
    setFilterToCurrent();
  };
  console.log('thanh selectedCommissionStatus', selectedCommissionStatus);

  const handleSetFilter = () => {
    setSelectedIsSelling(selectedCommissionStatus);
    setSelectedRating(parseInt(selectedStarRating, 10));
    // set min and max price base on price range
    let minPrice = 0;
    let maxPrice = 100000000;
    switch (selectedPriceRange) {
      case 'all':
        setSelectedMinPrice(0);
        setSelectedMaxPrice(100000000);
        minPrice = 0;
        maxPrice = 100000000;
        break;
      case 'under_500k':
        setSelectedMinPrice(0);
        setSelectedMaxPrice(499000);
        minPrice = 0;
        maxPrice = 499000;
        break;
      case '500k_to_1m':
        setSelectedMinPrice(500000);
        setSelectedMaxPrice(1000000);
        minPrice = 500000;
        maxPrice = 1000000;
        break;
      case '1m_to_2m':
        setSelectedMinPrice(1000000);
        setSelectedMaxPrice(2000000);
        minPrice = 1000000;
        maxPrice = 2000000;
        break;
      case 'over_2m':
        setSelectedMinPrice(2001000);
        setSelectedMaxPrice(100000000);
        minPrice = 2001000;
        maxPrice = 100000000;
        break;
      default:
        setSelectedMinPrice(0);
        setSelectedMaxPrice(100000000);
        minPrice = 0;
        maxPrice = 100000000;
        break;
    }
    applyFilter({
      filterMinPrice: minPrice,
      filterMaxPrice: maxPrice,
      filterIsSelling: selectedCommissionStatus,
      filterRating: parseInt(selectedStarRating, 10),
      filterCategoryId: selectedCategoryId,
    });
    setIsFilterPopupOpen(false);
  };

  const handleSetCategoryId = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    applyFilter({
      filterMinPrice: selectedMinPrice,
      filterMaxPrice: selectedMaxPrice,
      filterIsSelling: selectedCommissionStatus,
      filterRating: parseInt(selectedStarRating, 10),
      filterCategoryId: categoryId,
    });
  };

  const resetFilter = () => {
    setSelectedCommissionStatus('all');
    setSelectedPriceRange('all');
    setSelectedStarRating('0');
  };

  const setFilterToCurrent = () => {
    setSelectedCommissionStatus(selectedIsSelling);
    setSelectedStarRating(selectedRating.toString());
    if (selectedMinPrice === 0 && selectedMaxPrice === 100000000) {
      setSelectedPriceRange('all');
    } else if (selectedMinPrice === 0 && selectedMaxPrice === 499000) {
      setSelectedPriceRange('under_500k');
    } else if (selectedMinPrice === 500000 && selectedMaxPrice === 1000000) {
      setSelectedPriceRange('500k_to_1m');
    } else if (selectedMinPrice === 1000000 && selectedMaxPrice === 2000000) {
      setSelectedPriceRange('1m_to_2m');
    } else if (selectedMinPrice === 2001000 && selectedMaxPrice === 100000000) {
      setSelectedPriceRange('over_2m');
    }
  };

  // Show shadow when appbar is not at the top
  const hasReachedTop = usePageTop();

  return (
    <div
      className={`w-full px-4 md:px-6 lg:px-12 sticky top-12 bg-white z-40 ${
        hasReachedTop ? 'shadow' : ''
      }`}
    >
      <div className="w-full flex flex-row items-center">
        {/* isMobile */}
        <div className="w-fit h-full flex md:hidden flex-row justify-center items-center">
          <button
            className={
              activeFilterCounts > 0
                ? `w-11 h-fit py-1 flex flex-col justify-center items-center
                  rounded-lg border-2 border-greyscale-g5 relative`
                : 'w-11 h-fit py-1 flex flex-col justify-center items-center'
            }
            onClick={handleClickOpen}
          >
            {activeFilterCounts > 0 && (
              <div
                className="flex items-center justify-center text-xs font-semibold text-white
                w-4 h-4 rounded-full bg-greyscale-g5 absolute -top-1.5 -right-1.5"
              >
                {activeFilterCounts}
              </div>
            )}
            <Image
              src="/ic-filter-button-mobile.svg"
              priority
              width={24}
              height={24}
              alt="Filter"
            />
            <span className="text-xs text-content-t100 font-semibold whitespace-nowrap">
              Bộ lọc
            </span>
          </button>
          <div className="h-8 w-px bg-greyscale-g3 mx-3" />
        </div>

        <div className="flex-grow overflow-hidden embla" ref={ref}>
          <div className="relative embla__viewport" ref={emblaRef}>
            <div className="grid grid-flow-col gap-6 md:gap-8 embla__container">
              {Object.values(categoryIdIconMap).map((category: any) => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={handleSetCategoryId}
                  handleScrollToTop={handleScrollToTop}
                />
              ))}
            </div>
            {/* Only show the arrow button on desktop */}
            <button
              className={
                prevBtnDisabled
                  ? `hidden md:flex opacity-0 absolute left-4 top-6 z-10 bg-white/90
  rounded-full p-1.5 border-greyscale-g5 border hover:shadow-lg`
                  : `hidden md:flex absolute left-4 top-6 z-10 bg-white/90
  rounded-full p-1.5 border-greyscale-g5 border hover:shadow-lg`
              }
              onClick={scrollPrevPageEmbla}
            >
              <IconChevronLeftSvg viewBox="0 0 24 24" className="w-4 h-4" />
            </button>
            <button
              className={
                nextBtnDisabled
                  ? `hidden md:flex opacity-0 absolute right-4 top-6 z-10 bg-white/90
  rounded-full p-1.5 border-greyscale-g5 border hover:shadow-lg hover:bg-white`
                  : `hidden md:flex absolute right-4 top-6 z-10 bg-white/90
  rounded-full p-1.5 border-greyscale-g5 border hover:shadow-lg`
              }
              onClick={scrollNextPageEmbla}
            >
              <IconChevronRightSvg viewBox="0 0 24 24" className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* !isMobile */}
        <div className="w-32 h-full hidden md:flex flex-col justify-center items-end">
          <button
            className={
              activeFilterCounts > 0
                ? `w-24 h-11 flex flex-row justify-center items-center p-2 rounded-lg border-2
                  border-greyscale-g5 relative`
                : 'w-24 h-11 flex flex-row justify-center items-center p-2 rounded-lg border border-greyscale-g3'
            }
            onClick={handleClickOpen}
          >
            {activeFilterCounts > 0 && (
              <div
                className="flex items-center justify-center text-xs font-semibold text-white
                w-5 h-5 rounded-full bg-greyscale-g5 absolute -top-2 -right-2"
              >
                {activeFilterCounts}
              </div>
            )}
            <Image src="/ic-filter-button.svg" priority width={16} height={16} alt="Filter" />
            <span className="text-s-medium font-semibold text-content-t100 ml-2">Bộ lọc</span>
          </button>
        </div>
      </div>
      <Dialog
        open={isFilterPopupOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-filter"
      >
        {!!isMobile && (
          <div className="w-full max-w-[560px] flex flex-col">
            {/* Header  */}
            <div className="h-14 relative flex flex-row justify-center items-center">
              <button
                className="absolute left-4 flex justify-center items-center"
                onClick={handleClose}
              >
                <Image src="/ic-close.svg" priority width={24} height={24} alt="Close" />
              </button>
              <span className="text-m text-content-t100 font-bold">Bộ lọc</span>
            </div>
            <DrDivider />
            {/* Body  */}
            <div className="flex flex-col p-4">
              <div className="flex flex-col">
                <span className="text-xl text-content-t100 font-semibold">
                  Trạng thái commission của họa sĩ
                </span>
                <div className="flex flex-row mt-4 mb-6 h-9 gap-2 overflow-scroll no-scrollbar scroll-smooth">
                  <OptionButton
                    label="Tất cả"
                    isSelected={selectedCommissionStatus === 'all'}
                    isMobile={isMobile}
                    onClick={() => setSelectedCommissionStatus('all')}
                  />
                  <OptionButton
                    label="Mở commission"
                    isSelected={selectedCommissionStatus === 'open'}
                    isMobile={isMobile}
                    onClick={() => setSelectedCommissionStatus('open')}
                  />
                </div>
                <DrDivider />
              </div>
              <div className="flex flex-col mt-4">
                <span className="text-xl text-content-t100 font-semibold">Khoảng giá</span>
                <div className="flex flex-row flex-wrap mt-4 mb-4 w-full gap-2">
                  <OptionButton
                    label="Tất cả"
                    isSelected={selectedPriceRange === 'all'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('all')}
                  />
                  <OptionButton
                    label="Dưới ₫500k"
                    isSelected={selectedPriceRange === 'under_500k'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('under_500k')}
                  />
                  <OptionButton
                    label="₫500k - 1tr"
                    isSelected={selectedPriceRange === '500k_to_1m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('500k_to_1m')}
                  />
                  <OptionButton
                    label="₫1tr - 2tr"
                    isSelected={selectedPriceRange === '1m_to_2m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('1m_to_2m')}
                  />
                  <OptionButton
                    label="Trên ₫2tr"
                    isSelected={selectedPriceRange === 'over_2m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('over_2m')}
                  />
                </div>
              </div>
            </div>
            <DrDivider />
            {/* Footer  */}
            <div className="grid grid-cols-2 justify-center items-center h-24 py-4 px-2">
              <div className="flex flex-row justify-start items-center">
                <button
                  className="py-2 px-4 hover:bg-greyscale-g2 rounded-lg"
                  onClick={resetFilter}
                >
                  <span className="text-m text-content-t100 font-semibold underline">
                    Xóa tất cả
                  </span>
                </button>
              </div>
              <div className="flex flex-row justify-end items-center mr-2">
                <PrimaryButton sizeType="md" onClick={handleSetFilter}>
                  <span className="text-white text-m font-semibold">Xem kết quả</span>
                </PrimaryButton>
              </div>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="w-full max-w-[560px] flex flex-col">
            {/* Header  */}
            <div className="h-14 relative flex flex-row justify-center items-center">
              <button
                className="absolute left-6 flex justify-center items-center"
                onClick={handleClose}
              >
                <Image src="/ic-close.svg" priority width={24} height={24} alt="Close" />
              </button>
              <span className="text-m text-content-t100 font-bold">Bộ lọc</span>
            </div>
            <DrDivider />
            {/* Body  */}
            <div className="flex flex-col p-6">
              <div className="flex flex-col">
                <span className="text-xl text-content-t100 font-semibold">
                  Trạng thái commission của họa sĩ
                </span>
                <div className="flex flex-row mt-4 mb-6 h-9 gap-2">
                  <OptionButton
                    label="Tất cả"
                    isSelected={selectedCommissionStatus === 'all'}
                    isMobile={isMobile}
                    onClick={() => setSelectedCommissionStatus('all')}
                  />
                  <OptionButton
                    label="Mở commission"
                    isSelected={selectedCommissionStatus === 'open'}
                    isMobile={isMobile}
                    onClick={() => setSelectedCommissionStatus('open')}
                  />
                </div>
                <DrDivider />
              </div>
              <div className="flex flex-col mt-6">
                <span className="text-xl text-content-t100 font-semibold">Khoảng giá</span>
                <div className="flex flex-row mt-4 mb-4 h-9 gap-2 overflow-scroll no-scrollbar scroll-smooth">
                  <OptionButton
                    label="Tất cả"
                    isSelected={selectedPriceRange === 'all'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('all')}
                  />
                  <OptionButton
                    label="Dưới ₫500k"
                    isSelected={selectedPriceRange === 'under_500k'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('under_500k')}
                  />
                  <OptionButton
                    label="₫500k - 1tr"
                    isSelected={selectedPriceRange === '500k_to_1m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('500k_to_1m')}
                  />
                  <OptionButton
                    label="₫1tr - 2tr"
                    isSelected={selectedPriceRange === '1m_to_2m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('1m_to_2m')}
                  />
                  <OptionButton
                    label="Trên ₫2tr"
                    isSelected={selectedPriceRange === 'over_2m'}
                    isMobile={isMobile}
                    onClick={() => setSelectedPriceRange('over_2m')}
                  />
                </div>
              </div>
            </div>
            <DrDivider />
            {/* Footer  */}
            <div className="grid grid-cols-2 justify-center items-center h-24 py-6 px-4">
              <div className="flex flex-row justify-start items-center">
                <button
                  className="py-2 px-4 hover:bg-greyscale-g2 rounded-lg"
                  onClick={resetFilter}
                >
                  <span className="text-m text-content-t100 font-semibold underline">
                    Xóa tất cả
                  </span>
                </button>
              </div>
              <div className="flex flex-row justify-end items-center mr-2">
                <PrimaryButton sizeType="md" onClick={handleSetFilter}>
                  <span className="text-white text-m font-semibold">Xem kết quả</span>
                </PrimaryButton>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default HomePageFilterBar;
