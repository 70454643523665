import Image from 'next/image';
import { useEffect, useState } from 'react';

export type CategoryItemProps = {
  id: string;
  displayName: string;
  icon: string;
  hoverIcon: string;
  isNew?: boolean;
};

export function OptionButton({
  label,
  isSelected,
  isMobile,
  iconType = 'none',
  onClick,
}: {
  label: string;
  isSelected: boolean;
  isMobile?: boolean;
  iconType?: 'none' | 'star';
  onClick?: () => void;
}) {
  return (
    <button
      className={`w-fit h-9 flex flex-row justify-center items-center ${
        isMobile ? 'px-2 py-1 rounded-[40px]' : 'px-3 py-2 rounded-lg'
      }
      border border-greyscale-g3 ${isSelected ? 'bg-greyscale-g5' : ''}`}
      onClick={onClick}
    >
      {iconType === 'star' && (
        <div className="flex mr-[2px]">
          <Image src="/ic-profile-star.svg" width={16} height={16} alt="Star icon" />
        </div>
      )}
      <span
        className={`text-s-medium ${
          isSelected ? 'text-white' : 'text-content-t100'
        } whitespace-nowrap`}
      >
        {label}
      </span>
    </button>
  );
}

export function CategoryItem({
  category,
  selectedCategoryId,
  setSelectedCategoryId,
  handleScrollToTop,
}: {
  category: CategoryItemProps;
  selectedCategoryId: string;
  setSelectedCategoryId: (categoryId: string) => void;
  handleScrollToTop: () => void;
}) {
  /* States */
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(category.id === selectedCategoryId);
  }, [category, selectedCategoryId]);

  return (
    <div
      className={`embla__slide flex relative flex-col pt-3 md:pt-4 min-w-12 md:min-w-[56px]`}
      role="button"
      onClick={() => {
        setSelectedCategoryId(category.id);
        handleScrollToTop();
      }}
    >
      <div className="group flex flex-col justify-center items-center">
        <div className="group w-6 h-6 relative ">
          <div className="absolute inset-0">
            <Image
              src={isSelected ? category.hoverIcon : category.icon}
              priority
              width={24}
              height={24}
              alt="Category"
            />
          </div>

          <div className="hidden group-hover:flex absolute inset-0">
            <Image src={category.hoverIcon} priority width={40} height={28} alt="Category" />
          </div>
          {category.isNew && (
            <div
              className="absolute inset-0 flex items-center justify-center transform transition-transform
               translate-x-1/3 -translate-y-[40%] w-[40px] h-[28px]"
            >
              <Image src={'/ic-category-new.svg'} width={40} height={28} />
            </div>
          )}
        </div>

        <span
          className={`w-fit whitespace-nowrap font-medium
            text-xs md:text-s mt-0 md:mt-1
            ${isSelected ? 'text-content-t100' : 'text-content-t300 group-hover:text-content-t100'}
          `}
        >
          {category.displayName}
        </span>
        <div
          className={`w-full h-[2px] mt-2 md:mt-3
          ${isSelected ? 'bg-greyscale-g5' : 'group-hover:bg-greyscale-g3'}`}
        ></div>
      </div>
    </div>
  );
}
